.App {
  text-align: center;
}

.fader {
  -webkit-animation: fadein 7s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 7s; /* Firefox < 16 */
  -ms-animation: fadein 7s; /* Internet Explorer */
  -o-animation: fadein 7s; /* Opera < 12.1 */
  animation: fadein 7s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding-bottom: 3vh;
}

h1 {
  font-size: calc(100px + 2vmin);
  margin-bottom: 15px;
}

#App-header {
  background-color: #282c34;
  color: white;
}

#about {
  background-color: #30343d;
  color: white;
  justify-content: flex-start;
  padding-inline: 10vw;
}

#projects {
  background-color: #3d424e;
  color: white;
  justify-content: flex-start;
  padding-inline: 10vw;
}

.projdesc {
  text-align: left;
}

#projcards {
  padding-inline: 15vw;
  padding-top: 2vh;
  padding-bottom: 2vw;
}

.icon {
  color: white;
  font-size: 40px;
  padding-inline: 10px;
}

.tl {
  color: rgb(142, 153, 153);
  text-decoration: none;
}

.borda {
  background-color: #494f5e;
  margin-top: 2vh;
  border-radius: 20px;
  padding-inline: 2vh;
  padding-top: 2vh;
  padding-bottom: 2vh;
}
